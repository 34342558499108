// scroll functions and listeners

// imports --------------------------------------------------------------------
import * as Vars from './0-vars.js';
import * as Once from './2-once.js';
import * as Resize from './4-resize.js';

// exported variables ---------------------------------------------------------
export let scrollTop = 0;
export let scrollLeft = 0;
export let windowBottom = window.innerHeight;
export let windowRight = window.innerWidth;

export let atBodyBottom = false;
export let atBodyRight = false;
export let isScrolling = false;

// local variables ------------------------------------------------------------
let scrollDuration = 35; // default = 35

// scroll function ------------------------------------------------------------
export const setScroll = () => {
  // do scrolling actions
  // console.log('setScroll');

  if ('fixer' in Once && typeof Once.fixer.bodyFixed !== 'undefined') {
    if (Once.fixer.bodyFixed !== true) {
      updateFromScroll();
    }
  } else {
    updateFromScroll();
  }
};

const updateFromScroll = () => {
  scrollTop = window.scrollY || document.documentElement.scrollTop;
  scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  windowBottom = scrollTop + window.innerHeight;
  windowRight = scrollLeft + window.innerWidth;

  // use >= becaues of ios scrolling past the max, -1 for smooth scroll
  atBodyBottom = scrollTop >= Resize.maxBodyScrollTop - 1;
  atBodyRight = scrollLeft >= Resize.maxBodyScrollLeft - 1;

  // console.log('updateFromScroll', {scrollTop, scrollLeft, atBodyBottom, atBodyRight});


  if (scrollTop <= 0) {
    document.body.classList.remove('body--scrolled');
    document.body.classList.remove('body--nav-black');

    const splashEl = document.querySelector('.splash');
    if (splashEl) {
      document.body.classList.remove('body--nav-white');
    }  else {
      document.body.classList.add('body--nav-white');
    }
  } else {
    document.body.classList.add('body--scrolled');

    let whiteTop = 0;
    let rect;
    const splashRestEl = document.querySelector('.splash__rest');
    const mainWithBgEl = document.querySelector('.main__with-bg');
    const footerEl = document.querySelector('.footer');
  
    // get background color
    if (splashRestEl) {
      const bgColor = window.getComputedStyle(splashRestEl).backgroundColor;
      const rgb = bgColor.match(/\d+/g);

      // if white
      if (rgb[0] == 255) {
        rect = splashRestEl.getBoundingClientRect();
      } else {
        rect = mainWithBgEl.getBoundingClientRect();
      }
    } else {
      rect = mainWithBgEl.getBoundingClientRect();
    }
    
    whiteTop = rect.top;

    if (whiteTop < Resize.navHeight) {
      rect = footerEl.getBoundingClientRect();
      if (rect.top < Resize.navHeight) {
        document.body.classList.remove('body--nav-white');
        document.body.classList.add('body--nav-black');
      } else {
        document.body.classList.add('body--nav-white');
        document.body.classList.remove('body--nav-black');
      }
    } else {
      document.body.classList.remove('body--nav-white');
    }


    const interestHeaderEl = document.querySelector('.interest-header--dark');
    if (interestHeaderEl) {
      // check if sticky
      rect = interestHeaderEl.getBoundingClientRect();
      let top;
      if (Vars.mqMobile.matches) {
        top = Resize.navHeight;
      } else {
        top = Resize.navHeight + Vars.gutterMedium;
      }

      if (rect.top <= top + 1 && !Vars.mqMobileHorizontal.matches) {
        interestHeaderEl.classList.add('interest-header--sticky');
      } else {
        interestHeaderEl.classList.remove('interest-header--sticky');
      }
    }
  }

  // switch ($pageType) {
  //
  // }
};

// functions -------------------------------------------------------------------
export const checkBlockScroll = () => {
  // console.log('checkBlockScroll');
  // console.log('fixer' in Once);
  const hasFixer =  'fixer' in Once && typeof Once.fixer.bodyFixed !== 'undefined';
  const hasAnimator = 'animator' in Once;
  if (document.querySelector('.popover--active') || document.querySelector('.body--nav') || document.querySelector('.media--fullscreen')) {
    if (hasFixer) Once.fixer.fixBody();
    if (hasAnimator) Once.animator.disableSmoothScroll();
  } else {
    if (hasFixer) Once.fixer.unfixBody();
    if (hasAnimator && !isScrolling) Once.animator.enableSmoothScroll();
  }
};

export const scrollToEl = (el, returnFunction = '', offset = Resize.navHeight + 100) => {
  const rect = el.getBoundingClientRect();
  const top = rect.top + scrollTop - offset;
  animateScrollTop(top, document.body, returnFunction);
};

export const animateScrollTop = (
  scroll,
  element = document.body,
  returnFunction = '',
  useMax = true,
) => {
  // console.log('animateScrollTop', {scroll}, {element}, {returnFunction}, {useMax});

  let height;
  let scrollHeight;
  if (element.tagName === 'BODY') {
    height = window.innerHeight;
    scrollHeight = document.documentElement.scrollHeight;

    if ('animator' in Once) Once.animator.disableSmoothScroll();
  } else {
    // not body
    height = element.offsetHeight;
    scrollHeight = element.scrollHeight;
  }

  if (useMax) {
    // maxscroll is where the scroll reaches the bottom
    // don't go past that or the animation will not be smooth
    const maxScroll = scrollHeight - height;
    if (scroll > maxScroll) scroll = maxScroll;
    if (scroll < 0) scroll = 0;
  }

  // needs to change for body because of browser differences
  if (element.tagName === 'BODY') element = document.documentElement;

  // console.log({height}, {scroll});

  scrollToPosition(scroll, 'top', element, returnFunction);
};

export const animateScrollLeft = (
  scroll,
  element = document.body,
  returnFunction = '',
  useMax = true,
) => {
  // console.log('animateScrollLeft', {scroll}, {element}, {returnFunction}, {useMax});

  let width;
  let scrollWidth;
  if (element.tagName === 'BODY') {
    width = window.innerWidth;
    scrollWidth = document.documentElement.scrollWidth;
  } else {
    // not body
    width = element.offsetWidth;
    scrollWidth = element.scrollWidth;
  }

  if (useMax) {
    // maxscroll is where the scroll reaches the side
    const maxScroll = scrollWidth - width;
    if (scroll > maxScroll) scroll = maxScroll;
    if (scroll < 0) scroll = 0;
  }

  // needs to change for body because of browser differences
  if (element.tagName === 'BODY') element = document.documentElement;

  // console.log({width}, {scroll});

  scrollToPosition(scroll, 'left', element, returnFunction);
};

const scrollToPosition = (to, direction = 'top', element, returnFunction = '') => {
  // get document scroll
  let start;
  if (element == document.documentElement) {
    start = direction === 'top' ? window.scrollY || element.scrollTop : window.scrollX || element.scrollLeft;
  } else {
    start = direction === 'top' ? element.scrollTop : element.scrollLeft;
  }

  const change = to - start;
  if (change < 1 && change > -1) {
    if (element == document.documentElement) checkBlockScroll();
    if (typeof returnFunction === 'function') returnFunction();
    return;
  }

  isScrolling = true;
  let duration = Math.sqrt(Math.abs(change)) * scrollDuration;
  if (duration < Vars.transitionDefault) duration = Vars.transitionDefault;
  const increment = 15;
  let currentTime = 0;
  // console.log(element.tagName, {start, change, duration, increment, currentTime});

  const easeInOutQuart = t => (t < 0.5) ? (8 * (t ** 4)) : (1 - (8 * ((--t) * (t ** 3))));

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuart(currentTime / duration);
    const currentScroll = start + (change * val);
    if (direction === 'top') {
      element.scrollTo({top: currentScroll});
    } else {
      element.scrollTo({left: currentScroll});
    }

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    } else {
      isScrolling = false;
      if (element == document.documentElement) checkBlockScroll();
      if (typeof returnFunction === 'function') returnFunction();
    }
  };

  animateScroll();
};

// scroll listeners ------------------------------------------------------------
document.addEventListener('scroll', setScroll);
